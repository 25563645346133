import "./Button.scss";
import STAKE from './stakelogo.png';
import STAKE2 from './stakegif.gif';
import Make from "./makeitout.png";

//https://pancakeswap.finance/swap?outputCurrency=0x22A64572863E8B935444f4b84fc1C2314C041b10
const Button = () =>{
    return(
        <div>
            <div className="heading">
            <div className="Image-stake" style={{display: "flex", justifyContent: "center"}}>
                {/* <img src={STAKE2} style={{width: "40%"}} /> */}
                </div>

                <h1 className="top-heading">  <img src={Make} />  </h1><br></br><br></br>
                    <h1 className="best-looks" style={{justifyContent:"center" , textAlign:"center"}}>Trapped in a time loop, in a parallel dimension, your video camera is the only object that allows you to see the entities that inhabit this dimension. Survive by searching for ORBs that will allow you to fight and banish the entities. Make It Out is a Horror 3D gaming project which is built on the Binance Smart Chain. With breath-taking graphics and addictive gameplay, Make It Out is ready to create a uniquely immersive survive-to-earn experience. Collect NFTs and Orbs to fight entities that can also be exchanged for $MAKE</h1><br/>
                    <a style={{paddingTop:"40px"}} href="https://pancakeswap.finance/swap?inputCurrency=0x9778Ba3966702364544430326B3651230ac1a444&outputCurrency=BNB" target="_blank">
                        <button  className="button-85" >Buy $MAKE</button>
                    </a>
            </div>
     
        </div>
    )
}

export default Button;