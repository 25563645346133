/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/App.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar/Sidebar";

import Main from "./Pages/Home";

function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Main />} exact />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;


