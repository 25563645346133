//Mainnet all

// export const stakeAddress1 = "0x15CeB4efEeBe89efCa87b3174b9Ef11aCD662333";
// export const stakeAddress2 = "0xAE00E4cDbc65c09eFa26444DFABA6Ba39deE6c6B";
// export const stakeAddress3 = "0x7C3E11609F4258DB96F5676B231a072833fA590C";
// export const stakeAddress4 = "0x2385fd19624bcf426e3a6e58dd06a5de4e339e7c";
// export const stakeTokenAddress = "0x22A64572863E8B935444f4b84fc1C2314C041b10";
// export const rewardTokenAddress = "0x22A64572863E8B935444f4b84fc1C2314C041b10";



// Testnet all
// export const stakeAddress1 = "0xe420A9e4Ec4f1c0A7c23E54705bcD18d62853033"; //BUSD PLAN1 TESTNET



// export const stakeAddress2 = "0xe420A9e4Ec4f1c0A7c23E54705bcD18d62853033"; //PLAN2 //BUSD PLAN1 TESTNET
// export const stakeAddress3 = "0xe420A9e4Ec4f1c0A7c23E54705bcD18d62853033"; //PLAN 3 //BUSD PLAN1 TESTNET
// export const stakeAddress4 = "0xe420A9e4Ec4f1c0A7c23E54705bcD18d62853033"; //BUSD PLAN1 TESTNET


// export const stakeTokenAddress = "0xD7E7330Fa43786e8f1D2d797F568D422eD7799F5"; //Testnet Token
// export const rewardTokenAddress = "0xD7E7330Fa43786e8f1D2d797F568D422eD7799F5"; //Testnet busd token



// DOGE  AND BUSD WEBSITE
export const stakeAddress1 = "0x6b8f3cc5cd8F440B6778a852BCA8FaA2d78f6a2d";//Plan 1 make it out//-------- //"0x506f5e34ec795a5190001bf95e8a9da386919c91"; //Plan 1 Mainnet //"0xA235E8416942f70350aF53Bd13815D1fEd2F4b4C"; //18 Decima plan



export const stakeAddress2 ="0x02B076F4Ab9C8a4873344CF4bbc4B5E579B2e367"; //Plan 2 Make It Out //"0x24f1606c15231183485cc0e24a684b71a04a438b"; // PLAN 1 TESTNET 
export const stakeAddress3 = "0xbc57fa9cd5865abce42c83403300d058858b1023";// Plan 3 Mainnet    //"0xF8c045e079dEa47b2099C7299bBF05799c1b1114"; // PLAN 1 TESTNET
export const stakeAddress4 = "0x7Fa83e0aBa754b4909252fA4F2d48dc6Db9855D5"; // PLAN 1 TESNET


export const stakeTokenAddress = "0x9778Ba3966702364544430326B3651230ac1a444"; //Mainnet Make It Out //"0xD7E7330Fa43786e8f1D2d797F568D422eD7799F5"; //STAKE TOKEN BUSD 18 DECIMAL
export const rewardTokenAddress = "0x9778Ba3966702364544430326B3651230ac1a444"; //Mainnet Make It Out//"0xD7E7330Fa43786e8f1D2d797F568D422eD7799F5"; //REWARD TOKEN SBLOCK 18 DECIMAL