import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';

// import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.css';


export default function Footer() {
  return (
    <MDBFooter className='bg-dark text-center text-white' style={{ backgroundColor: '#2b0202' }} >
      <MDBContainer className='p-4 pb-0'>
        <section className='mb-4 mx-5'>
          <MDBBtn outline color="light" floating className='m-1 mx-4' href='' role='button'>
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1 mx-4' href='https://www.twitter.com/makeitoutbsc' target='_blank' role='button'>
            <MDBIcon fab icon='twitter' />
          </MDBBtn>

          <MDBBtn outline color="light" floating className='m-1 mx-4' href='https://t.me/MakeItOutOfficial' role='button' target='_blank'>
            <MDBIcon fab icon='telegram' />
          </MDBBtn>
          {/* <MDBBtn outline color="light" floating className='m-1 mx-4' href='https://www.instagram.com/reel/Coy71UPgAl1/?igshid=YmMyMTA2M2Y' role='button' target='_blank'>
            <MDBIcon fab icon='instagram' />
          </MDBBtn> */}
{/* 
          <MDBBtn outline color="light" floating className='m-1 mx-4' href='#!' role='button' target='_blank'>
            <MDBIcon fab icon='linkedin-in' />
          </MDBBtn> */}

          {/* <MDBBtn outline color="light" floating className='m-1 mx-4' href='' role='button' target='_blank'>
            <MDBIcon fab icon='tiktok' />
          </MDBBtn> */}
        </section>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © Make It Out 
        <a className='text-white' href='https://makeitout.io/' target="_blank">
        
        </a>
      </div>
    </MDBFooter>
  );
}