import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { useAccount } from "wagmi";
import getTimePeriods from "../../time";
import Web3 from "web3";
import { MaxUint256 } from "@ethersproject/constants";
import { parseUnits } from "ethers/lib/utils";

import { stakeTokenAddress, stakeAddress4 } from "../../config";

import { stakeAbi } from "../../abi/stake";
import { tokenAbi } from "../../abi/token";

const web3 = new Web3(
  Web3.givenProvider || "https://bsc-dataseed1.binance.org"
);

const tokenContract = new web3.eth.Contract(tokenAbi, stakeTokenAddress);
const stake1Contract = new web3.eth.Contract(stakeAbi, stakeAddress4);

const StakeFour = (props) => {
  const [activeTab, setActiveTab] = useState("stake");
  const [requestedApproval, setRequestedApproval] = useState(false);
  const [pendingTx, setPendingTx] = useState(false);
  const [amount, setAmount] = useState(0);
  const { address } = useAccount();
  const BIG_TEN = new BigNumber(10);

  const tokenStakeOne = async () => {
    const units = parseUnits(amount, 9);
    return stake1Contract.methods
      .deposit(units.toString())
      .send({ from: address, gas: 200000 })
      .on("transactionHash", (tx) => {
        return tx.transactionHash;
      });
  };

  const tokenWithdrawOne = async () => {
    const units = parseUnits(stakeBal, 9);
    return stake1Contract.methods
      .withdraw(units.toString())
      .send({ from: address, gas: 200000 })
      .on("transactionHash", (tx) => {
        return tx.transactionHash;
      });
  };

  const harvestReward = async () => {
    return stake1Contract.methods
      .harvest()
      .send({ from: address, gas: 200000 })
      .on("transactionHash", (tx) => {
        return tx.transactionHash;
      });
  };

  const handleSubmit = useCallback(async () => {
    try {
      const txHash = await tokenStakeOne();
      // user rejected tx or didn't go thru
      if (!txHash) {
      }
    } catch (e) {
      console.error(e);
    }
  }, [tokenStakeOne]);

  const useAllowance = () => {
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const { address } = useAccount();

    const fetchAllowance = useCallback(async () => {
      const allowance = await tokenContract.methods
        .allowance(address, stakeAddress4)
        .call();
      setAllowance(new BigNumber(allowance));
    }, [address]);

    useEffect(() => {
      if (address) {
        fetchAllowance();
      }
      const refreshInterval = setInterval(fetchAllowance, 10000);
      return () => clearInterval(refreshInterval);
    }, [address, fetchAllowance]);

    return allowance;
  };

  const allowance = useAllowance();

  const onApprove = async () => {
    return tokenContract.methods
      .approve(stakeAddress4, web3.utils.toWei(amount.toString(), "ether"))
      .send({ from: address, gas: 200000 })
      .on("transactionHash", (tx) => {
        return tx.transactionHash;
      });
  };

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true);
      const txHash = await onApprove();
      // user rejected tx or didn't go thru
      if (!txHash) {
        setRequestedApproval(false);
      }
    } catch (e) {
      console.error(e);
    }
  }, [onApprove, setRequestedApproval]);

  const UserReward = () => {
    const [reward, setReward] = useState(0);
    const { address } = useAccount();

    const fetchData = useCallback(async () => {
      const result = await stake1Contract.methods.pendingReward(address).call();
      const pendingR = new BigNumber(result)
        .dividedBy(BIG_TEN.pow(9))
        .toNumber();
      setReward(pendingR.toFixed(6));
    }, [address]);

    useEffect(() => {
      if (address) {
        fetchData();
      }
      const refreshInterval = setInterval(fetchData, 1000);
      return () => clearInterval(refreshInterval);
    }, [address]);

    return reward;
  };

  const rew = UserReward();

  const UserUnlock = () => {
    const [unlock, setUnlockTime] = useState(0);
    const { address } = useAccount();

    const fetchData = useCallback(async () => {
      const result = await stake1Contract.methods.UnlockTime(address).call();
      const time = new BigNumber(result);
      setUnlockTime(time.toNumber());
    }, [address]);

    useEffect(() => {
      if (address) {
        fetchData();
      }
      const refreshInterval = setInterval(fetchData, 1000);
      return () => clearInterval(refreshInterval);
    }, [address, fetchData]);

    return unlock;
  };

  const unlock = UserUnlock();

  const UserBalStake = () => {
    const [stakeBal, setStakeBal] = useState(0);
    const { address } = useAccount();

    const fetchData = useCallback(async () => {
      const result = await stake1Contract.methods.userInfo(address).call();
      const bal = new BigNumber(result.amount)
        .dividedBy(BIG_TEN.pow(9))
        .toNumber();
      setStakeBal(bal.toFixed(4));
    }, [address]);

    useEffect(() => {
      if (address) {
        fetchData();
      }
      const refreshInterval = setInterval(fetchData, 1000);
      return () => clearInterval(refreshInterval);
    }, [address, fetchData]);

    return stakeBal;
  };

  const useApy = () => {
    const [apy, setApy] = useState(0);

    const fetchData = useCallback(async () => {
      const result = await stake1Contract.methods.apyRate().call();
      const apyres = new BigNumber(result).dividedBy(100).toNumber();
      setApy(apyres);
    }, []);

    useEffect(() => {
      fetchData();
      const refreshInterval = setInterval(fetchData, 1000);
      return () => clearInterval(refreshInterval);
    }, [fetchData]);

    return apy;
  };

  const apy = useApy();

  const stakeBal = UserBalStake();

  const currentSeconds = Math.floor(Date.now() / 1000)
  const hasUnlocked = currentSeconds > unlock
  const hasEarnings = rew > 0

  const secondsRemaining = unlock - Date.now() / 1000
  const days = Math.floor(secondsRemaining / (3600 * 24))
  const hours = Math.floor((secondsRemaining % (3600 * 24)) / 3600)
  const minutes = Math.floor((secondsRemaining % 3600) / 60)
  const unloked = address ? unlock < Date.now() / 1000 : null

  return (
    <div className="card flex flex-col items-center">
      <div className="card-tag">Goldblox Plan:4</div>
      <div className="card-block flex-col items-center">
        <div className="selection-tabs flex items-center">
          <div
            className={`tab flex items-center justify-center ${
              activeTab == "stake" ? "active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("stake");
            }}
          >
            Stake
          </div>
          <div
            className={`tab flex items-center justify-center ${
              activeTab == "withdraw" ? "active" : ""
            }`}
            onClick={(e) => {
              setActiveTab("withdraw");
            }}
          >
            Reward
          </div>
        </div>
        {activeTab === "stake" ? (
          <div className="meta flex flex-col justify-between">
            <div className="fields-box flex flex-col">
              <div className="field flex flex-col">
                <div className="f-tag">Stake Token Amount</div>
                <div className="input-box flex items-center mb-6">
                  <input
                    type="number"
                    className="txt w-full"
                    placeholder="Stake token amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="item flex items-center justify-between">
                <div className="lbl">ROI</div>
                <div className="val">{apy}%</div>
              </div>
              <div className="item flex items-center justify-between mb-6">
                <div className="lbl">TIME</div>
                <div className="val">365 Days</div>
              </div>
            </div>
            <div className="action flex items-center justify-center">
              {!allowance.toNumber() ? (
                <button
                  className="btn button-85"
                  disabled={requestedApproval}
                  onClick={handleApprove}
                >
                  {pendingTx ? "Approving Gold" : "Approve Gold"}
                </button>
              ) : (
                <button
                  className="btn button-85"
                  disabled={pendingTx}
                  onClick={async () => {
                    setPendingTx(true);
                    try {
                      await handleSubmit();
                    } catch (e) {
                      console.error(e);
                    } finally {
                      setPendingTx(false);
                    }
                  }}
                >
                  {pendingTx ? "Waiting" : "Stake"}
                </button>
              )}
            </div>
          </div>
        ) : activeTab === "withdraw" ? (
          <div className="meta flex flex-col">
            <div className="field-action flex items-center w-full">
              <div className="val min-w-[100px]">{rew}</div>
              <button
                className="btn button-85 w-full"
                disabled={!hasEarnings}
                onClick={harvestReward}
              >
                Claim
              </button>
            </div>
            <div className="field-action flex items-center w-full">
              <div className="val min-w-[100px]">{stakeBal}</div>
              <button
                className="btn button-85 w-full"
                disabled={!hasEarnings || !hasUnlocked}
                onClick={tokenWithdrawOne}
              >
                Withdraw
              </button>
            </div>
            <div className="item flex items-center justify-between mb-6">
              <div className="lbl">Unlock In</div>
              <div className="val">
                {unloked ? (
                  <>
                    <p>{0 + " d " + 0 + " h " + 0 + " m"}</p>
                  </>
                ) : (
                  <>
                    {address ? (
                      <p>{days + " d " + hours + " h " + minutes + " m"}</p>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StakeFour;
