import React, { useState, useEffect } from "react";
// import { tokenAbi } from "../../abi/token";

import Web3 from "web3";

import "./Balance.scss";


import { stakeTokenAddress, stakeAddress1 } from "../../config";

import { stakeAbi } from "../../abi/stake";
import { tokenAbi } from "../../abi/token";

import Busd from "./busd.png";

const web3 = new Web3(Web3.givenProvider || "https://bsc-dataseed1.binance.org");

const tokenContract = new web3.eth.Contract(tokenAbi, stakeTokenAddress);



const TokenBalance = () => {
  const [balance, setBalance] = useState(0);
  const [address, setAddress] = useState("");

  useEffect(() => {
    const load = async () => {
      const accounts = await web3.eth.getAccounts();
      setAddress(accounts[0]);
      const balance = await tokenContract.methods.balanceOf(accounts[0]).call();
      setBalance(parseFloat(web3.utils.fromWei(balance, "ether")).toFixed(2));

    };
    load();
  }, []);

  return (
    <div className="container">
    <div className="row">
      <div className="col">
        <div className="heading text-center" style={{ border: "1px solid black", padding: "10px" }}>
          <p style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: "24px" }}>
            Your wallet: {balance} MAKE
            {/* <img src={Busd} alt="BUSD" style={{ marginLeft: "5px", width: "20px", height: "20px" }} /> */}
          </p>
        </div>
      </div>
    </div>
  </div>
  
  
  );
};

export default TokenBalance;
