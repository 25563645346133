import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const { chains, provider } = configureChains(
  [bsc],
  [publicProvider()],
)
 
const client = createClient({
  autoConnect: true,
  provider,
})

ReactDOM.render(
  <WagmiConfig client={client}>
  <BrowserRouter>
      <App />
    </BrowserRouter>
  </WagmiConfig>,
  document.getElementById("root")
);

reportWebVitals();
